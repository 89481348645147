@font-face {
    font-family: 'Roobert';
    src: local('Roobert-Regular'),
        url('./fonts/Roobert-Regular.woff2') format('woff2'),
        url('./fonts/Roobert-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Krona One';
    src: local('Krona One'), local('KronaOne-Regular'),
        url('./fonts/KronaOne-Regular.woff2') format('woff2'),
        url('./fonts/KronaOne-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

$pageBackground: #edead5;

html{
    margin:0;
    padding:0;
    overflow: hidden;
    }
body{
    position: absolute;
    width:100%;
    height:100%;
    overflow: auto;
    }
body, html{
    overflow-x: hidden;
}
  
body{
    font-family: 'Roobert';
    background-color: $pageBackground;
}

.footer{
    font-family: 'Roobert';
    color: rgba(0,0,0,1);
}